
import Vue, { PropOptions } from 'vue';
import MessageTimestamp from '@/views/communication/MessageTimestamp.vue';

export default Vue.extend({
    name: 'Message',
    components: {
        MessageTimestamp
    },
    props: {
        body: {
            type: String,
            required: true
        } as PropOptions<string>,
        senderName: {
            type: String,
            required: true
        } as PropOptions<string>,
        createdAt: {
            type: String
        } as PropOptions<string>,
        isMessageFromCurrentUser: {
            type: Boolean,
            required: true
        } as PropOptions<boolean>,
        isFirstLoad: {
            type: Boolean,
            required: true
        } as PropOptions<boolean>
    },
    computed: {
        firstname(): string {
            return this.senderName.split(' ')[0];
        },
        initials(): string {
            const splitName = this.senderName.split(' ');

            if (splitName.length > 1) {
                return `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`;
            } else {
                return splitName[0].charAt(0);
            }
        }
    },
    mounted() {
        const messageRef = this.$refs.message as HTMLElement;

        if (messageRef && !this.isFirstLoad) {
            messageRef.scrollIntoView({ behavior: 'smooth' });
        }
    }
});
