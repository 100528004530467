
import Vue from 'vue';
import MessengerHeader from '@/views/communication/MessengerHeader.vue';
import Message from '@/views/communication/Message.vue';
import SendMessage from '@/views/communication/SendMessage.vue';
import { getDocumentCommunication } from '@/services/communication';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { DocumentCommunicationData, DocumentData, MessageData } from 'dexxter-types/communication';
import { mapState } from 'vuex';
import { sortMessages } from '@/views/communication/communication.helpers';
import { isAccountant } from '@/helpers/auth';

export default Vue.extend({
    name: 'Messenger',
    components: { MessengerHeader, Message, SendMessage },
    props: {
        documentId: {
            type: Number,
            required: true
        },
        polymorphicDocumentId: {
            type: Number,
            required: true
        },
        recipientName: {
            type: String,
            default: ''
        },
        inModal: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            messages: [] as MessageData[],
            document: null as DocumentData | null,
            solved: false,
            refreshInterval: 30_000,
            refreshCommunicationIntervalRef: null as ReturnType<typeof setInterval> | null,
            isFirstLoad: true
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        documentType(): string | null {
            return this.document && this.document.documentType;
        },
        isUserAccountant(): boolean {
            return isAccountant(this.currentUserData);
        }
    },
    watch: {
        messages: {
            handler(newMessages: MessageData[], oldMessages: MessageData[]) {
                if (newMessages.length > oldMessages.length) {
                    this.$emit('refresh-communications');
                }
            },
            deep: true
        },
        documentId: {
            handler() {
                this.isFirstLoad = true;
                this.getCommunication();
            },
            immediate: true
        }
    },
    created() {
        this.getCommunication();

        this.refreshCommunicationIntervalRef = setInterval(() => {
            this.getCommunication();
        }, this.refreshInterval);
    },
    beforeDestroy() {
        if (this.refreshCommunicationIntervalRef) {
            clearInterval(this.refreshCommunicationIntervalRef);
        }
    },
    methods: {
        sortMessages(unsortedMessages: MessageData[]): MessageData[] {
            return sortMessages(unsortedMessages);
        },
        async getCommunication(): Promise<void> {
            try {
                const {
                    messages: newMessages,
                    document,
                    solved
                }: DocumentCommunicationData = await getDocumentCommunication(this.documentId);

                this.messages = this.sortMessages(newMessages);
                this.document = document;
                this.solved = solved;

                await this.$nextTick();

                if (this.isFirstLoad) {
                    this.scrollDown();
                    this.isFirstLoad = false;
                }
            } catch (e: any) {
                if (e.response.status !== 404) {
                    apiErrorAndDisplay.call(this, e);
                }
            }
        },
        handleUpdateCommunication({ messages, solved }: { messages: MessageData[]; solved: boolean }): void {
            this.$emit('refresh-communications');
            this.messages = this.sortMessages(messages);
            this.solved = solved;
        },
        scrollDown(): void {
            const messagesRef = this.$refs.messages as HTMLElement;
            if (messagesRef) {
                messagesRef.scrollTo(0, 999999);
            }
        },
        isMessageFromCurrentUser(senderId: number): boolean {
            return this.currentUserData.id === senderId;
        }
    }
});
