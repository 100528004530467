import { generateEndpoint } from '@/services/config';
import axios from 'axios';
import { DocumentCommunicationData, MessageType } from 'dexxter-types/communication';

export const BASE_URL_COMMUNICATION = generateEndpoint('/api/communication/');

export function getDocumentCommunication(documentId: number): Promise<DocumentCommunicationData> {
    return axios.get(`${BASE_URL_COMMUNICATION}${documentId}`).then((response) => response.data);
}

export function sendMessageRequest(
    documentId: number,
    body: string,
    messageType: MessageType
): Promise<DocumentCommunicationData> {
    return axios
        .post(`${BASE_URL_COMMUNICATION}${documentId}`, { body, messageType })
        .then((response) => response.data);
}

export function getAllCommunications(solved: boolean | undefined): Promise<DocumentCommunicationData[]> {
    return axios.get(`${BASE_URL_COMMUNICATION}`, { params: { solved: solved } }).then((response) => response.data);
}
