
import Vue from 'vue';

export default Vue.extend({
    name: 'SendSolutionIcon',
    props: {
        color: {
            type: String,
            default: '#A3A3A3'
        },
        size: {
            type: Number,
            default: 22
        }
    }
});
