
import Vue from 'vue';
import SendSolutionIcon from '@/components/Icons/SendSolutionIcon.vue';
import { MessageType } from 'dexxter-types';
import { sendMessageRequest } from '@/services/communication';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default Vue.extend({
    name: 'SendMessage',
    components: { SendSolutionIcon },
    props: {
        documentId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            newMessage: '',
            isMenuOpen: false,
            selectedSendOptionIndex: 0
        };
    },
    computed: {
        textFieldHasText(): boolean {
            return this.newMessage.length > 0;
        },
        sendIconColor(): string {
            return this.textFieldHasText ? '#436BEC' : '#C6C4C4';
        },
        selectedSendOption(): MessageType {
            return this.selectedSendOptionIndex === 0 ? 'normal' : 'solution';
        }
    },
    methods: {
        async sendMessage(messageType: MessageType): Promise<void> {
            try {
                const { messages: unsortedMessages, solved } = await sendMessageRequest(
                    this.documentId,
                    this.newMessage,
                    messageType
                );
                this.$emit('update-communication', {
                    messages: unsortedMessages,
                    solved
                });

                this.newMessage = '';
            } catch (e) {
                apiErrorAndDisplay(this, e);
            }
        },
        handleEnterPress(): void {
            if (this.isMenuOpen) {
                this.sendMessage(this.selectedSendOption);
                this.isMenuOpen = false;
            } else {
                this.isMenuOpen = true;
            }
        },
        decreaseSelectedSendOption(): void {
            if (this.selectedSendOptionIndex > 0) {
                this.selectedSendOptionIndex--;
            }
        },
        incrementSelectedSendOption(): void {
            if (this.selectedSendOptionIndex < 1) {
                this.selectedSendOptionIndex++;
            }
        }
    }
});
