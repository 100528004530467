
import Vue, { PropOptions } from 'vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export default Vue.extend({
    name: 'MessengerHeader',
    props: {
        recipientName: {
            type: String,
            required: false,
            default: ''
        } as PropOptions<string>,
        inModal: {
            type: Boolean,
            default: false
        } as PropOptions<boolean>,
        documentType: {
            type: String
        } as PropOptions<string>,
        documentId: {
            type: Number,
            required: false
        } as PropOptions<number>,
        polymorphicDocumentId: {
            type: Number,
            required: false
        } as PropOptions<number>
    },
    computed: {
        title(): string | TranslateResult {
            return this.recipientName !== '' ? this.recipientName : this.$t('communication.ask_question');
        },
        lowerCaseDocumentType(): string {
            return this.documentType && this.documentType.toLowerCase();
        },
        polymorphicDocumentIdString(): string {
            return this.polymorphicDocumentId.toString();
        }
    },
    methods: {
        openDocument(): void {
            let routeData;
            if (this.lowerCaseDocumentType === 'quotation') {
                routeData = this.$router.resolve({
                    name: 'invoicable-view',
                    params: { type: this.lowerCaseDocumentType, id: this.polymorphicDocumentIdString }
                });
            } else if (this.lowerCaseDocumentType === 'invoice' || this.lowerCaseDocumentType === 'creditnote') {
                routeData = this.$router.resolve({
                    name: 'accounting-invoicable-view',
                    params: { type: this.lowerCaseDocumentType, id: this.polymorphicDocumentIdString }
                });
            } else if (this.lowerCaseDocumentType === 'expense') {
                routeData = this.$router.resolve({
                    name: 'view-accounting-expense',
                    params: { id: this.polymorphicDocumentIdString }
                });
            }
            if (routeData) window.open(routeData.href, '_blank');
        }
    }
});
