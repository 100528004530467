
import Vue, { PropOptions } from 'vue';
import VueI18n from 'vue-i18n';
import {
    getFormattedTimeFromIsoDateString,
    getReadableRelativeDateNotationFromIsoDateString
} from '@/helpers/dateHelpers';
import TranslateResult = VueI18n.TranslateResult;

export default Vue.extend({
    name: 'MessageTimestamp',
    props: {
        createdAt: {
            type: String,
            required: true
        } as PropOptions<Date | string>
    },
    computed: {
        day(): string | TranslateResult {
            return getReadableRelativeDateNotationFromIsoDateString(this.createdAt);
        },
        time(): string {
            return getFormattedTimeFromIsoDateString(this.createdAt);
        }
    }
});
